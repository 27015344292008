import { Link } from "gatsby";
import React, { Component } from "react";
import image from '../images/whatsapp.png';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };
  }

  render() {
    const { data, header } = this.props;
    const { menu } = this.state;
    return (
      <header className={`site-header ${menu ? "active" : ""}`}>
        <div className="container">
          <div className="header-main">
            <div className="logo">
              <Link to="/">
                {data.logo.file.url ? (
                  <><img style={{width:'50px', height:'50px'}} src={data.logo.file.url} alt="logo"/><span style={{textDecoration: 'none'}}>Tovar Psicología</span></>
                ) : (
                  <span>{data.siteName}</span>
                )}
              </Link>
            </div>
            <div className="whatsapp">
              <a style={{size: '12px', textDecoration: 'none', display:'flex', color: '#ef5826!important'}}
                //className="fab fa-whatsapp"
                href={'https://api.whatsapp.com/send?phone=34644176260'}
                target="_blank"
                rel="noopener noreferrer"
              ><img style={{width:'30px', height:'30px'}} src={image}></img><span style={{alignSelf:'center', marginLeft:'10px'}}>644176260</span></a>
            </div>
            <div
              className="responsive-menu"
              onClick={() => {
                this.setState({
                  menu: !menu
                });
              }}
            >
              <span></span>
            </div>
            {header === "home" ? (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false
                    });
                  }}
                >
                  <li>
                    <Link to="/#home">Home</Link>
                  </li>
                  {data.menus
                    .filter(item => item === "About")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#About`}>Sobre Mí</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Service")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Service`}>Especialidades</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Testimonials")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Testimonials`}>Servicios</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Contact")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Contact`}>Contacto</Link>
                        </li>
                      );
                    })}
                    {data.menus
                    .filter(item => item === "Blogs")
                    .map(t => {
                      return (
                        <li>
                          <Link to={`/#Blogs`}>Blog</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false
                    });
                  }}
                >
                  <li>
                    <Link to="/#home">Home</Link>
                  </li>
                  {data.menus
                    .filter(item => item === "Blogs")
                    .map(t => {
                      return (
                        <li>
                          <Link to="/#Blogs">Blog</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

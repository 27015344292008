import React, { Component } from "react";
import image from '../images/whatsapp.png';

export default class footer extends Component {
  render() {
    return (
      <div className="site-footer" id="footer">
        <div className="container">
          <span>{this.props.siteName} © 2021. Todos los derechos reservados.</span>
        </div>
      </div>
    );
  }
}
